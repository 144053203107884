import React from "react";

const Logo = ({mainColor}) => {
  
  const textColor = `text-`+mainColor || `text-white`;
  const borderColor = `border-t-`+mainColor || `border-t-white`;  

  return (
    <div id="brand" className="container flex flex-row  p-1 m-0 ">
            {/*<img src={logo} alt="CLEANVerif Compliance Verification App." 
                className=" sm:w-20 w-10 mb-2"/>*/}
            <div id="brand-logo" className="flex relative -mb-4 sm:-mb-4 content-end">
              {/*
                <svg viewBox="0 0 509.604 509.604" className="fill-green-500 content-end mr-1 w-5 h-5 sm:w-6 sm:h-6 md:h-7 md:w-7 -z-10">
                  <path d="M34.262,333.282c8.119,6.75,14.793,15.223,14.143,20.988c-0.382,3.443-0.593,6.943-0.593,10.5
                  c0,52.393,41.3,94.861,92.24,94.861c6.292,0,12.431-0.65,18.37-1.885c10.002-2.074,21.812,1.941,28.888,9.793
                  c16.82,18.646,40.803,30.342,67.492,30.342c28.19,0,53.426-13.016,70.342-33.518c6.723-8.146,18.103-11.533,28.22-8.5
                  c8.166,2.447,16.811,3.768,25.751,3.768c50.939,0,92.24-42.477,92.24-94.861c0-5.861-0.535-11.59-1.549-17.145
                  c-1.712-9.371,2.85-21.047,10.471-28.363c18.025-17.289,29.328-41.883,29.328-69.242c0-29.787-13.368-56.323-34.263-73.698
                  c-8.118-6.751-14.793-15.224-14.143-20.99c0.383-3.442,0.593-6.942,0.593-10.5c0-52.393-41.301-94.86-92.24-94.86
                  c-6.292,0-12.431,0.65-18.369,1.884c-10.002,2.075-21.812-1.941-28.889-9.792c-16.82-18.647-40.803-30.342-67.492-30.342
                  c-26.688,0-50.671,11.695-67.492,30.342c-7.076,7.841-18.886,11.867-28.888,9.792c-5.938-1.234-12.078-1.884-18.37-1.884
                  c-50.939,0-92.24,42.477-92.24,94.86c0,5.049,0.392,10.002,1.147,14.832c1.262,8.128-4.447,18.149-12.747,24.681
                  C14.219,201.663,0,228.887,0,259.583C0,289.37,13.368,315.907,34.262,333.282z M131.475,263.016
                  c2.046-3.625,7.268-3.672,12.049,0.479l48.119,33.918c2.61,1.588,5.106,2.4,7.506,2.4c4.963,0,8.893-3.576,12.689-7.02
                  l153.985-154.138c9.629-10.471,18.99-14.162,25.102-10.146c2.82,1.855,4.646,4.647,5.135,7.87
                  c0.583,3.825-0.756,7.946-3.768,11.599l-185.149,224.91c-2.687,3.26-6.11,5.059-9.629,5.059c-4.179,0-7.965-2.516-10.404-6.895
                  l-54.344-97.969C130.519,269.422,130.021,265.618,131.475,263.016z"/>
	              </svg>
                <svg width="800px" height="800px" viewBox="0 -43.5 1111 1111" className="icon w-20 h-20"  version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M508.342857 844.8c198.948571-53.394286 200.411429-52.662857 200.411429-52.662857s5.12 82.651429-68.022857 106.057143l-207.725715 56.32s-11.702857-81.188571 75.337143-109.714286zM701.44 672.182857c29.988571-13.165714 61.44-36.571429 94.354286-70.948571 68.754286-72.411429 89.234286-132.388571 89.234285-235.52 0-165.302857-138.971429-299.885714-310.857142-299.885715s-310.857143 134.582857-310.857143 299.885715c0 111.177143 45.348571 211.382857 160.182857 282.331428l-21.211429-36.571428c0 10.971429-0.731429 20.48-2.925714 36.571428-3.657143 33.645714-4.388571 40.96-2.194286 57.051429 4.388571 27.062857 19.748571 49.005714 46.811429 62.171428l20.48-39.497142-27.062857 35.108571c15.36 10.971429 32.182857 12.434286 51.2 10.24 10.24-1.462857 21.211429-3.657143 35.108571-7.314286 2.925714-0.731429 6.582857-1.462857 10.24-2.925714 2.194286-0.731429 10.24-2.925714 11.702857-3.657143 3.657143-1.462857 6.582857-2.194286 9.508572-2.925714 2.194286-0.731429 4.388571-1.462857 5.851428-1.462857h-2.194285v-87.771429c-8.777143 0-16.091429 1.462857-27.062858 4.388572-3.657143 0.731429-6.582857 2.194286-11.702857 2.925714-1.462857 0.731429-9.508571 2.925714-11.702857 3.657143-3.657143 0.731429-6.582857 1.462857-8.777143 2.194285-9.508571 2.194286-16.091429 4.388571-21.211428 4.388572 2.925714 0.731429 7.314286 2.194286 13.165714 5.851428l-2.925714-2.194285-3.657143-1.462857s2.194286 2.925714 2.194286 3.657142c-0.731429-5.12 0-10.971429 2.194285-34.377142 2.194286-19.017143 2.925714-31.451429 2.925715-46.08v-24.137143l-21.211429-13.165715c-85.577143-52.662857-118.491429-125.074286-118.491429-208.457142 0-117.028571 98.742857-212.114286 220.16-212.114286s220.16 95.085714 220.16 212.114286c0 82.651429-13.165714 120.685714-65.097142 176.274285-24.868571 26.331429-47.542857 42.422857-65.828572 51.2-5.12 2.194286-9.508571 3.657143-13.165714 4.388572 1.462857 0 1.462857 87.771429 1.462857 87.771428 13.165714 1.462857 30.72-2.194286 51.2-11.702857z" fill="#76BC43" />
                <path d="M650.971429 599.771429l95.085714-36.571429v128c0 26.331429-21.211429 47.542857-47.542857 47.542857h-47.542857v-138.971428z" fill="#76BC43" />
                <path d="M574.171429 541.257143s135.314286-82.651429 135.314285-157.257143-60.708571-135.314286-135.314285-135.314286-135.314286 60.708571-135.314286 135.314286 135.314286 157.257143 135.314286 157.257143z" fill="#e9fff9" />
                <path d="M574.171429 541.257143s43.885714-27.062857 81.92-63.634286c-169.691429-185.051429-10.24-8.777143-175.542858-190.902857-25.6 24.868571-41.691429 59.245714-41.691428 97.28 0 74.605714 135.314286 157.257143 135.314286 157.257143z" fill="#9ed8db" />
                <path d="M574.171429 541.257143s45.348571-27.794286 83.382857-65.097143c27.794286-27.794286 51.931429-60.708571 51.931428-92.16 0-40.96-12.434286-92.16-43.885714-97.28-81.188571 97.28 0 2.925714-88.502857 107.52-2.925714 154.331429-2.925714 147.017143-2.925714 147.017143z" fill="#467599" />
                </svg>
              */}
              
              <svg width="800" height="800" viewBox="263.314 65.829 621.714 888.686" className="icon w-20 h-20" xmlns="http://www.w3.org/2000/svg">
	<path d="M508.343 844.8c198.948-53.394 200.411-52.663 200.411-52.663s5.12 82.652-68.023 106.057l-207.725 56.32s-11.703-81.188 75.337-109.714M701.44 672.183c29.989-13.166 61.44-36.572 94.354-70.949 68.755-72.411 89.235-132.388 89.235-235.52 0-165.303-138.972-299.885-310.858-299.885S263.314 200.41 263.314 365.714c0 111.177 45.349 211.383 160.183 282.332l-21.211-36.572c0 10.972-.732 20.48-2.926 36.572-3.657 33.645-4.389 40.96-2.194 57.051 4.388 27.063 19.748 49.006 46.811 62.172l20.48-39.498-27.063 35.109c15.36 10.971 32.183 12.434 51.2 10.24 10.24-1.463 21.212-3.657 35.109-7.314 2.926-.732 6.583-1.463 10.24-2.926 2.194-.731 10.24-2.926 11.703-3.657 3.657-1.463 6.583-2.194 9.508-2.926 2.195-.731 4.389-1.463 5.852-1.463h-2.195v-87.771c-8.777 0-16.091 1.463-27.062 4.388-3.658.732-6.583 2.195-11.703 2.926-1.463.732-9.509 2.926-11.703 3.657q-5.486 1.097-8.777 2.195c-9.509 2.194-16.092 4.388-21.212 4.388 2.926.732 7.315 2.194 13.166 5.852l-2.926-2.195-3.657-1.463s2.194 2.926 2.194 3.658c-.731-5.12 0-10.972 2.195-34.378 2.194-19.017 2.925-31.451 2.925-46.08v-24.137L471.04 572.71c-85.577-52.663-118.491-125.075-118.491-208.458 0-117.028 98.742-212.114 220.16-212.114s220.16 95.086 220.16 212.114c0 82.652-13.166 120.686-65.098 176.275-24.868 26.331-47.542 42.423-65.828 51.2q-7.68 3.291-13.166 4.388c1.463 0 1.463 87.772 1.463 87.772 13.166 1.463 30.72-2.195 51.2-11.703" fill="#76BC43"/>
	<path d="m650.971 599.771 95.086-36.571v128c0 26.331-21.211 47.543-47.543 47.543h-47.543z" fill="#76BC43"/>
	<path d="M574.171 541.257S709.486 458.606 709.486 384 648.777 248.686 574.17 248.686 438.857 309.394 438.857 384s135.314 157.257 135.314 157.257" fill="#e9fff9"/>
	<path d="M574.171 541.257s43.886-27.063 81.92-63.634C486.4 292.57 645.851 468.846 480.55 286.72c-25.6 24.869-41.692 59.246-41.692 97.28 0 74.606 135.314 157.257 135.314 157.257" fill="#9ed8db"/>
	<path d="M574.171 541.257s45.349-27.794 83.383-65.097c27.795-27.794 51.932-60.709 51.932-92.16 0-40.96-12.435-92.16-43.886-97.28-81.189 97.28 0 2.926-88.503 107.52-2.926 154.331-2.926 147.017-2.926 147.017" fill="#467599"/>
</svg>

              
                
              
              
             
            </div>
            <div id="brand-text" className="flex flex-col">
              <div id="brand-name" className="flex flex-row">
                
                <h1 className={`${textColor} justify-end right font-bolder text-xl md:text-2xl`}>
                  <span className={`text-base font-light ${textColor} hover:${textColor} content-center`}>clean</span>
                  Smart
                  <span className={`text-xs font-extralight ${textColor}`}>®</span> 
                </h1>
              </div>
              <span id="brand-slogan" 
                className={`-z-5 justify-end ${textColor} hover:${textColor} text-xs md:text-xs font-extralight border-t ${borderColor} text-nowrap`}>
                Compliance Management App.
              </span>
            </div>
            
          
    </div>
  );
};

export default Logo;
